import React from "react";

const Circles = ({ variant, ...props }) => {
  if (variant === "multi")
    return (
      <svg
        {...props}
        viewBox="0 0 590 604"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M334.13 114.816l-229.134 208.8 255.275 280.135 229.134-208.8L334.13 114.816z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M323.962 333.783c63.274-57.658 67.826-155.693 10.167-218.967-57.659-63.274-155.694-67.825-218.967-10.167-63.274 57.659-67.826 155.694-10.167 218.967 57.658 63.274 155.693 67.826 218.967 10.167z"
          fill="#4AC1E0"
        />
        <path
          d="M485.291 107.454l-36.522 33.534L520.227 220l36.522-33.534-71.458-79.012z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M483.67 142.62c10.086-9.26 10.811-25.004 1.621-35.166-9.19-10.162-24.817-10.893-34.902-1.633-10.085 9.26-10.811 25.005-1.62 35.167 9.19 10.162 24.816 10.893 34.901 1.632z"
          fill="#E4D54F"
        />
        <g clipPath="url(#clip0)">
          <path
            d="M469.562 196.12l-14.092 12.938 27.571 30.485 14.091-12.938-27.57-30.485z"
            fill="url(#paint2_linear)"
          />
          <path
            d="M468.936 209.688c3.891-3.573 4.171-9.647.625-13.568a9.481 9.481 0 00-13.466-.63c-3.891 3.573-4.171 9.647-.625 13.568a9.481 9.481 0 0013.466.63z"
            fill="#FC7B00"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="219.563"
            y1="219.216"
            x2="436.109"
            y2="456.85"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC7B00" stopOpacity=".59" />
            <stop offset="1" stopColor="#FC7B00" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="467.03"
            y1="124.221"
            x2="528.148"
            y2="190.786"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC7B00" stopOpacity=".59" />
            <stop offset="1" stopColor="#FC7B00" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="462.516"
            y1="202.589"
            x2="486.097"
            y2="228.271"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC7B00" stopOpacity=".59" />
            <stop offset="1" stopColor="#FC7B00" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0">
            <path
              fill="#fff"
              transform="translate(449 189)"
              d="M0 0h49v50.543H0z"
            />
          </clipPath>
        </defs>
      </svg>
    );

  if (variant === "orange")
    return (
      <svg
        {...props}
        viewBox="0 0 590 604"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M334.13 114.816l-229.134 208.8 255.275 280.135 229.134-208.8L334.13 114.816z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M323.962 333.783c63.274-57.658 67.826-155.693 10.167-218.967-57.659-63.274-155.694-67.825-218.967-10.167-63.274 57.659-67.826 155.694-10.167 218.967 57.658 63.274 155.693 67.826 218.967 10.167z"
          fill="#FC7B00"
        />
        <path
          d="M485.291 107.454l-36.522 33.534L520.227 220l36.522-33.534-71.458-79.012z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M483.67 142.62c10.086-9.26 10.811-25.004 1.621-35.166-9.19-10.162-24.817-10.893-34.902-1.633-10.085 9.26-10.811 25.005-1.62 35.167 9.19 10.162 24.816 10.893 34.901 1.632z"
          fill="#FC7B00"
        />
        <g clipPath="url(#clip0)">
          <path
            d="M469.562 196.12l-14.092 12.938 27.571 30.485 14.091-12.938-27.57-30.485z"
            fill="url(#paint2_linear)"
          />
          <path
            d="M468.936 209.688c3.891-3.573 4.171-9.647.625-13.568a9.481 9.481 0 00-13.466-.63c-3.891 3.573-4.171 9.647-.625 13.568a9.481 9.481 0 0013.466.63z"
            fill="#FC7B00"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="219.563"
            y1="219.216"
            x2="436.109"
            y2="456.85"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC7B00" stopOpacity=".59" />
            <stop offset="1" stopColor="#FC7B00" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="467.03"
            y1="124.221"
            x2="528.148"
            y2="190.786"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC7B00" stopOpacity=".59" />
            <stop offset="1" stopColor="#FC7B00" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="462.516"
            y1="202.589"
            x2="486.097"
            y2="228.271"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC7B00" stopOpacity=".59" />
            <stop offset="1" stopColor="#FC7B00" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0">
            <path
              fill="#fff"
              transform="translate(449 189)"
              d="M0 0h49v50.543H0z"
            />
          </clipPath>
        </defs>
      </svg>
    );
  else return null;
};

export default Circles;
