import React from "react";
import { Link } from "gatsby";
import day from "dayjs";

export default function Insight({ insight, taxonomy, type }) {
  if (type === "card")
    return (
      <Link to={insight.node?.uri} className="insight__item">
        <div className="insight__item__img-wrapper">
          <img
            className="insight__item__img"
            src={insight.node?.featuredImage?.node?.sourceUrl}
            alt=""
          />
        </div>
        <div className="insight__item__text-wrapper">
          <div className="flex space-between">
            <span className="insight__item__category">
              {taxonomy ? taxonomy : insight.node.categories.nodes?.[0]?.name}
            </span>
            <span className="insight__item__date">
              {day(insight.node?.date).format("D MMMM YYYY")}
            </span>
          </div>
          <p className="insight__item__title">{insight.node.title}</p>
        </div>
      </Link>
    );

  return (
    <Link to={insight.node.uri} className="insight__item--row-wrapper">
      <div className="insight__item__img-wrapper">
        <img
          className="insight__item__img--row"
          src={insight.node?.featuredImage?.node?.sourceUrl}
          alt=""
        />
      </div>
      <div className="insight__item--row">
        <strong className="insight__item__category text-blue">
          {taxonomy ? taxonomy : insight.node.categories.nodes?.[0]?.name}
        </strong>
        <p className="insight__item__title">{insight.node.title}</p>
        <span className="insight__item__date text-gray">
          {day(insight.node?.date).format("D MMMM YYYY")}
        </span>
      </div>
    </Link>
  );
}
