import React from "react";
import cx from "classnames";

export default function Quadrant({ index, card, cards }) {
  return (
    <div className="cards--quadrant__wrapper">
      <div
        to=""
        className={cx({
          "cards__card--first-two": index < 2,
          "cards__card--last-two ":
            cards?.length % 2 === 0 && index > cards?.length - 3,
          "cards__card--last-one ":
            cards?.length % 2 !== 0 && index > cards?.length - 2,
          cards__card: true,
        })}
      >
        {card.image && (
          <img src={card.image.sourceUrl} alt="" className="card__image" />
        )}
        <p className="card__title text-blue">{card.title}</p>
        <div
          className="wysiwyg__content"
          dangerouslySetInnerHTML={{ __html: card.text }}
        />
      </div>
    </div>
  );
}
