import React from "react";
import { navigate } from "gatsby";

export default function Column({ card }) {
  return (
    <div
      to=""
      className="cards--column__item pointer"
      onClick={() => (card.linkUri ? navigate(card.linkUri) : null)}
      onKeyDown={() => (card.linkUri ? navigate(card.linkUri) : null)}
      role="link"
      tabIndex="0"
    >
      {card.image && (
        <img src={card.image.sourceUrl} alt="" className="card__image" />
      )}
      <p className="card__title text-blue">{card.title}</p>
      <div
        className="wysiwyg__content"
        dangerouslySetInnerHTML={{ __html: card.text }}
      />
    </div>
  );
}
