import React from "react";

import Quadrant from "./quadrant";
import Column from "./column";
import Circle from "./circle";

export default function Cards({ cards, type }) {
  if (type?.toLowerCase() === "quadrant")
    return (
      <div className="cards--quadrant">
        {cards.map((card, index) => (
          <Quadrant key={index} {...{ card, index, cards }} />
        ))}
      </div>
    );
  if (type?.toLowerCase() === "column")
    return (
      <div className="cards--column__items">
        {cards.map((card, index) => (
          <Column key={index} {...{ card }} />
        ))}
      </div>
    );
  if (type?.toLowerCase() === "circles")
    return (
      <div className="cards--circles">
        {cards.map((card, index) => (
          <Circle key={index} {...{ card }} />
        ))}
      </div>
    );
  return null;
}
