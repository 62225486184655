import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Circles from "../components/svg/Circles";
import Insight from "../components/insight/insight";
import Cards from "../components/cards";

import orangeDots from "../images/orange-dots.png";

export default function Home({ data }) {
  const { seo, ...page } = data.page;
  const latestInsights = data.latestInsights.edges;

  return (
    <Layout>
      <Seo seo={seo} />

      <header className="page__featured-image__wrapper">
        <img
          className="page__featured-image"
          src={page?.featuredImage?.node?.sourceUrl}
          alt=""
        />
        <div className="page__featured-content">
          <div
            className="wysiwyg__content"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </div>
        <Circles className="home__circle-svg" variant="orange" />
      </header>

      {/* <section className="home__content">
        <div
          className="wysiwyg__content"
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
      </section> */}

      <section className="pt-4">
        {page.cards?.cards && (
          <Cards type={page.cards?.type} cards={page.cards?.cards} />
        )}
      </section>

      <section className="home__insights">
        <h2 className="home__insights__heading">Latest Insights</h2>
        <div className="home__insights__items">
          {latestInsights.map((insight, index) => (
            <Insight type="card" key={index} insight={insight} />
          ))}
        </div>

        <img className="home__insights__image" src={orangeDots} alt="" />
      </section>
    </Layout>
  );
}

/**
 * queries
 */
export const query = graphql`
  {
    page: wpPage(slug: { eq: "home" }) {
      id
      title
      content
      featuredImage {
        node {
          sourceUrl
        }
      }
      seo {
        fullHead
      }
      cards {
        type
        cards {
          text
          title
          linkUri
          image {
            sourceUrl
          }
        }
      }
    }
    latestInsights: allWpInsight(
      sort: { fields: date, order: DESC }
      filter: { insight: { showonhomepage: { eq: true } } }
      limit: 6
    ) {
      edges {
        node {
          uri
          date
          categories {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
          title
        }
      }
    }
  }
`;
