import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { navigate } from "gatsby";

export default function Circle({ card }) {
  /**
   * state
   * */
  const [showText, setShowText] = useState(false);
  const element = useRef(null);

  /**
   * effects
   * */
  useEffect(() => {
    const handleMouseEnter = (event) => {
      if (!element || !card.text || typeof window == "undefined") {
        return;
      }
      setShowText(true);
    };

    const handleMouseLeave = (event) => {
      if (!element || !card.text || typeof window == "undefined") {
        return;
      }
      setShowText(false);
    };

    element.current.addEventListener("mouseenter", handleMouseEnter);
    element.current.addEventListener("mouseleave", handleMouseLeave);

    // for mobile devices. TODO: cleanup/refactor
    // window.addEventListener("touchmove", (event) => {
    //   if (!canvasEl || typeof window == "undefined") {
    //     return;
    //   }

    //   const activeFrame = Math.round(
    //     (event.touches[0].pageX / document.documentElement.clientWidth) *
    //       FRAME_COUNT
    //   );

    //   window.requestAnimationFrame(() =>
    //     scrubThroughFrames(canvasEl.current, activeFrame)
    //   );
    // });

    const cleanup = element.current;

    return () => {
      if (typeof window !== "undefined") {
        cleanup.removeEventListener("mouseenter", handleMouseEnter);
        cleanup.removeEventListener("mouseleave", handleMouseEnter);
      }
    };
  }, [card.text]);

  return (
    <div
      ref={element}
      className={cx({
        cards__circle: true,
        pointer: card.linkUri || card.text,
      })}
      onClick={() => (card.linkUri ? navigate(card.linkUri) : null)}
      onKeyDown={() => (card.linkUri ? navigate(card.linkUri) : null)}
      role="link"
      tabIndex="0"
      style={{ backgroundColor: card.backgroundColour }}
    >
      <div className="cards__circle__wrapper">
        {showText ? (
          <p
            className="cards__circle__text"
            dangerouslySetInnerHTML={{
              __html: card.text,
            }}
          />
        ) : (
          <p
            className="cards__circle__title"
            dangerouslySetInnerHTML={{
              __html: card.title,
            }}
          />
        )}
      </div>
    </div>
  );
}
